export default {
  'request.10000': '不存在的企業',
  'request.10001': '用戶名或密碼錯誤',
  'request.10002': '試用期已到期',
  'request.10003': '驗證碼輸入錯誤',
  'request.10004': '企業名稱已存在，請重新輸入',
  'request.10005': '該手機號已注冊，請勿重複操作',
  'request.10006': '原密碼與新密碼相同，不用更改密碼',
  'request.10007': '當前用戶不存在',
  'request.10008': '原密碼輸入錯誤',
  'request.10009': '當前用戶已經登陸，不能重複登陸，請稍後再試',
  'request.10010': '當前登錄用戶數已超出系統允許的最大用戶數量',
  'request.10011': '該郵箱已注冊，請勿重複操作',
  'request.10012': '設備號已注冊，請核查',
  'request.10013': '操作過于頻繁，請1分鍾後再試',
  'request.10014': '系統激活失敗，請右鍵點擊驗證碼以獲取系統激活碼',
  'request.10015': '當前用戶尚未分配任何權限，請聯系管理員設置權限後再登錄系統',
  'request.10016': '請插入加密狗',
};
