export default {
  'common.table.batchdel': 'Batch Delete',
  'common.table.del': 'Delete',
  'common.table.moveup': 'Move Up',
  'common.table.movedown': 'Move Down',
  'common.table.cancel': 'Cancel',
  'common.table.selected': 'Selected',
  'common.table.item': 'Items',
  'common.btn.success': 'Operation Successful',
  'common.btn.download.success': 'Data download successful',
  'common.btn.download.failed': 'Data download failed',
  'common.btn.confirm': 'Confirm',
  'common.btn.cancel': 'Cancel',
  'common.btn.close': 'Close',
  'common.btn.template': 'Download template',
  'common.btn.import.label': 'File Name',
  'common.btn.export.excel': 'Export Excel',
  'common.btn.export.pdf': 'Export Pdf',
  'common.btn.import.title': 'Click to Upload',
  'common.btn.home': 'Return Home',
  'common.message.prompt': 'prompt',
  'common.placeholder.message': 'Please enter ',
  'common.device.nodata': 'No data in the device',
  'common.notification.description.nodata':
    'No matching records found, please change inquired condition.',
  'common.notification.description.batchsuccess': 'Batch read successfully, the total records is: ',
  'common.notification.description.records': ' Number of records actually entered is: ',
  'common.notification.description.required': 'This item is required',
  'common.notification.query.range':
    'The query time range cannot exceed 60 days. Please re-select the query criteria',
};
