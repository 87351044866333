export default {
  'query.condition.dept': '区域名称：',
  'query.condition.guard': '人员名称',
  'query.condition.site': '地点名称',
  'query.condition.event': '事件名称',
  'query.condition.plan': '计划名称',
  'query.condition.planguard': '计划人员',
  'query.condition.device': '设备号码',
  'query.condition.begin': '开始时间',
  'query.condition.end': '结束时间',
  'query.condition.time': '巡检时间',
  'query.condition.alarmtype': '报警类型',
  'query.input.placeholder.site': '请选择地点',
  'query.input.placeholder.guard': '请选择人员',
  'query.input.placeholder.device': '请选择设备',
  'query.input.placeholder.plan': '请选择计划',
  'query.input.placeholder.alarm': '请选择报警类型',
  'query.history.title': '历史数据',
  'query.history.btn.reanalysis': '重新分析',
  'query.app.remark': '内容及备注',
  'query.app.multimedia': '多媒体',
  'query.app.view': '查看',
  'query.app.notes': '备注',
  'query.app.view.pict': '查看图片',
  'query.app.view.video': '查看视频',
  'query.app.view.audio': '查看音频',
  'query.app.options': '选项内容',
  'query.app.state': '状态',
  'query.app.notentered': '未输入',
  'query.app.notselected': '未选择',
  'query.app.selected': '已选择',
  'query.app.site.errspan': '当前点的经纬度误差范围超出规定范围，该点可能不是有效的巡检点',
  'query.app.site.time': '当前巡检时间可能被修改过，请联系巡检人员以确认',
  'query.app.site.both':
    '当前点的经纬度误差范围超出规定范围，该点可能不是有效的巡检点，同时，当前巡检时间可能被修改过，请联系巡检人员以确认',
  'query.alarm.title': '报警数据',
  'query.alarm.btn.del': '删除数据',
  'query.alarm.type.all': '全部报警',
  'query.alarm.type.manual': '手动报警',
  'query.alarm.type.lowvoltage': '低电报警',
  'query.alarm.type.incline': '倾斜报警',
  'query.alarm.type.static': '静止报警',
  'query.alarm.type.impact': '摔碰报警',
  'query.alarm.type.open': '开壳报警',
  'query.alarm.info': '报警信息',
  'query.alarm.time': '报警时间',
  'query.alarm.position': '报警位置',
  'query.omit.title': '漏检数据',
  'query.omit.rangetime': '起止时间',

  'query.chart.title': '图表数据',
  'query.chart.table.plantimes': '计划数量',
  'query.chart.table.arrivedtimes': '到达数量',
  'query.chart.table.omittimes': '漏检数量',
  'query.chart.table.arrivedrate': '合格率',
  'query.chart.table.omitrate': '漏巡率',
  'query.chart.table.summary': '数据汇总',
  'query.chart.tab.data': '统计数据',
  'query.chart.tab.chart': '图表展示',
  'query.chart.tab.chart.column': '柱形图',
  'query.chart.tab.chart.bar': '条形图',
  'query.chart.tab.chart.line': '线形图',
  'query.chart.tab.omit': '漏检数据',
  'query.chart.condition.type': '统计类型',
  'query.chart.condition.dept': '按区域信息统计',
  'query.chart.condition.guard': '按人员信息统计',
  'query.chart.condition.site': '按地点信息统计',
  'query.chart.condition.plan': '按计划信息统计',

  'query.raw.title': '原始数据',
  'query.raw.table.card': '卡号',
  'query.raw.table.type': '卡类型',
  'query.raw.table.name': '卡号名称',
  'query.raw.table.unknow': '未知卡',
  'query.raw.table.event': '事件卡',
  'query.raw.table.guard': '人员卡',
  'query.raw.table.site': '地点卡',
  'query.raw.table.time': '打卡时间',
  'query.raw.btn.unknow': '未知卡转换',
  'query.raw.btn.site': '转为地点卡',
  'query.raw.btn.guard': '转为人员卡',
  'query.raw.btn.event': '转为事件卡',

  'query.log.title': '日志数据',
  'query.log.table.operate': '操作界面',
  'query.log.table.content': '操作内容',
  'query.log.table.time': '操作时间',
  'query.log.table.user': '操作人',
  'query.log.form.dept': '区域设置',
  'query.log.form.guard': '人员设置',
  'query.log.form.site': '地点设置',
  'query.log.form.plan': '计划设置',
  'query.log.form.read': '读取数据',

  'query.calendar.january': '1月',
  'query.calendar.february': '2月',
  'query.calendar.march': '3月',
  'query.calendar.april': '4月',
  'query.calendar.may': '5月',
  'query.calendar.june': '6月',
  'query.calendar.july': '7月',
  'query.calendar.august': '8月',
  'query.calendar.september': '9月',
  'query.calendar.october': '10月',
  'query.calendar.november': '11月',
  'query.calendar.december': '12月',
  'query.calendar.year': '年',
  'query.calendar.year1': '年',
  'query.calendar.month': '月',

  'query.report.condition.type': '数据类型',
  'query.report.condition.all': '全部数据',
  'query.report.condition.normal': '合格数据',
  'query.report.condition.todo': '待巡数据',
  'query.report.table.alltimes': '全部数量',
  'query.report.table.patroltimes': '巡检次数',
  'query.report.table.topatrol': '待巡数量',
  'query.report.btn.export.summary': '导出汇总表',
  'query.report.btn.export.ordinary': '导出明细表',
  'query.report.btn.export.summaryname': '报表汇总数据',
  'query.report.btn.export.ordinaryname': '报表明细数据',
  'query.report.table.step': '步数',
  'query.report.btn.del': '删除巡检报告',

  'query.history.analysis': '确定要对选定的记录进行重新分析处理吗？',
  'query.content.fold': '全部折叠',
  'query.content.expand': '全部展开',
};
