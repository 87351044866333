export default {
  'request.10000': 'Λάθος Εταιρία',
  'request.10001': 'Λάθος όνομα χρήστη ή κωδικός',
  'request.10002': 'Η δοκιμαστική περίοδος τελείωσε',
  'request.10003': 'Λάθος κωδικός επιβεβαίωσης',
  'request.10004': 'Η ονομασία Εταιρίας υπάρχει ήδη. Εισάγετε πάλι εταιρία',
  'request.10005': 'Ο αριθμός κινητού έχει καταχωρηθεί, παρακαλώ μην επαναλάβετε τη λειτουργία',
  'request.10006': 'Ίδιος Κωδικός',
  'request.10007': 'Μην βγείτε απο το μενού χρήστη',
  'request.10008': 'Λάθος τρέχων κωδικός',
  'request.10009':
    'Είστε συνδεδεμένοι. Δεν μπορείτε να συνδεθείτε πάλι. Παρακαλώ δοκιμάστε πάλι αργότερα',
  'request.10010':
    'Ο αριθμός των συνδεδεμένων χρηστών έχει υπερβεί τον μέγιστο αριθμό χρηστών που επιτρέπεται από το σύστημα',
  'request.10011': 'Το email έχει καταχωρηθεί, μην επαναλάβετε τη λειτουργία',
  'request.10012': 'Ο αριθμός συσκευής είναι καταχωρημένος. Παρακαλώ δοκιμάστε πάλι',
  'request.10013': 'Παρακαλώ δοκιμάστε πάλι σε 1 λεπτό',
  'request.10014':
    'Η ενεργοποίηση συστήματος απέτυχε. Κάντε δεξί κλικ στον κωδικό επαλήθευσης για να λάβετε τον κωδικό ενεργοποίησης του συστήματος',
  'request.10015':
    'Στον τρέχοντα χρήστη δεν έχουν εκχωρηθεί δικαιώματα. Παρακαλούμε επικοινωνήστε με τον διαχειριστή για να ορίσετε δικαιώματα πριν συνδεθείτε στο σύστημα.',
  'request.10016': 'Παρακαλώ εισάγετε το dongle',
};
