export default {
  'user.title': '操作员信息',
  'user.btn.add': '增加操作员',
  'user.btn.dept': '查看区域',
  'user.btn.right': '权限分配',
  'user.btn.init': '恢复初始密码',
  'user.btn.home': '返回首页',
  'user.table.displayname': '登陆名',
  'user.table.username': '操作员姓名',
  'user.table.rolename': '所属角色',
  'user.table.operate': '操作',
  'user.table.edit': '编辑',
  'user.role.title': '操作员权限分配',
  'user.role.label': '角色列表',
  'user.role.message': '请选择角色',
  'user.input.username.placeholder': '请输入操作员姓名',
  'user.input.displayname.placeholder': '请输入登陆名',
  'user.input.email.placeholder': '请输入Email',
  'user.btn.init.confirm': '确定要恢复成初始密码吗？（初始密码：999）',

  'user.serial.title': '系统激活',
  'user.serial.activation': '激活码',
  'user.serial.verification': '验证码',
  'user.serial.verification.placeholder': '请输入验证码',
  'user.app.expand': 'app扩充',
  'user.app.count': '申请数量',
};
