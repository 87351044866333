import menu from './en-US/menu';
import pwa from './en-US/pwa';
import exception from './en-US/exception';
import request from './en-US/request';
import login from './en-US/login';
import dashboard from './en-US/dashboard';
import header from './en-US/header';
import general from './en-US/general';
import map from './en-US/map';
import device from './en-US/device';
import system from './en-US/system';
import sound from './en-US/sound';
import communication from './en-US/communication';
import alarm from './en-US/alarm';
import dept from './en-US/dept';
import event from './en-US/event';
import guard from './en-US/guard';
import site from './en-US/site';
import role from './en-US/role';
import talk from './en-US/talk';
import user from './en-US/user';
import content from './en-US/content';
import book from './en-US/book';
import receipt from './en-US/receipt';
import plan from './en-US/plan';
import query from './en-US/query';
import common from './en-US/common';
import bi from './en-US/bi';
import company from './en-US/company';

export default {
  ...menu,
  ...pwa,
  ...exception,
  ...request,
  ...login,
  ...dashboard,
  ...header,
  ...general,
  ...map,

  ...device,
  ...system,
  ...sound,

  ...communication,
  ...alarm,
  ...dept,
  ...event,
  ...guard,
  ...site,
  ...common,
  ...user,
  ...role,
  ...content,
  ...book,
  ...plan,
  ...receipt,
  ...query,
  ...talk,

  ...bi,
  ...company,

  app: 'Cloud',
  producer: 'JWM Hi-Tech Development Ltd.',
  oldaddress: 'Old version address',
};
