export default {
  'plan.title': '巡检计划',
  'plan.title.special': '按次计划设置',
  'plan.btn.add': '增加计划',
  'plan.btn.addTemporary': '增加临时计划',
  'plan.btn.bind': '绑定设备',
  'plan.btn.copy': '复制计划',
  'plan.btn.paste': '粘贴计划',
  'plan.btn.export': '导出计划',
  'plan.btn.batch': '批量日程',
  'plan.btn.addshift': '增加日程',
  'plan.btn.delshift': '删除日程',
  'plan.btn.guard': '设置人员',
  'plan.btn.addrest': '增加休息日',
  'plan.btn.Manu': '手动绑定',
  'plan.modal.label.next': '次日',
  'plan.modal.label.onetime': '巡检一次用时（分钟）',
  'plan.modal.label.resttime': '每次间休时间（分钟）',
  'plan.modal.label.no': '第 ',
  'plan.modal.label.day': ' 日',
  'plan.modal.label.weekrest': '每周休息日',
  'plan.modal.label.specialrest': '特殊休息日',
  'plan.modal.label.period': '巡检周期',
  'plan.modal.label.unit': '单位',
  'plan.modal.label.plantimes': '计划次数',
  'plan.modal.label.times': '次',
  'plan.modal.label.cost': '每圈用时',
  'plan.modal.label.interval': '两圈间隔',
  'plan.modal.label.month': '月',
  'plan.modal.label.week': '周',
  'plan.modal.label.minute': '分钟',
  'plan.modal.label.hour': '小时',
  'plan.modal.label.days': '日',
  'plan.table.schedule': '日程',
  'plan.table.restday': '休息日',
  'plan.table.monday': '周一',
  'plan.table.tuesday': '周二',
  'plan.table.wednesday': '周三',
  'plan.table.thursday': '周四',
  'plan.table.friday': '周五',
  'plan.table.saturday': '周六',
  'plan.table.sunday': '周日',
  'plan.table.type': '计划类型',
  'plan.table.per': '每 ',
  'plan.table.month': ' 个月',
  'plan.table.patrol': '巡检',
  'plan.tab.plan': '普通计划',
  'plan.tab.special': '按次计划',
  'plan.tab.schedule': '巡检日程',
  'plan.step.basic': '基础信息设置',
  'plan.step.site': '巡检地点设置',
  'plan.step.shift': '巡检日程设置',
  'plan.step.next': '下一步',
  'plan.step.prev': '上一步',
  'plan.step.submit': '提交',
  'plan.step.rest': '休息日设置',
  'plan.transfer.site.select': '已选地点',
  'plan.transfer.site.unselect': '待选地点',
  'plan.input.plan.placeholder': '请输入计划名称',
  'plan.notification.description.save.success': '计划保存成功',
  'plan.notification.description.sitefirst': '请先设置巡检地点',
  'plan.notification.description.schedulefirst': '请先设置巡检日程',
  'plan.notification.description.exitplan':
    '此计划存在已经生成的当天数据，是否需要覆盖当天数据，重新生成数据？',
  'plan.notification.description.than24': '单次巡检用时不能超过24小时，请重新设置',
  'plan.notification.description.repeat': '班次时间段有重合，请重新设置',
  'plan.notification.description.date': '结束时间必须大于开始时间',
  'plan.notification.description.exitdate': '选择的日期已存在，请重新选择',
  'plan.notification.description.exitdevice': '当前设备已存在，请勿重复添加',
  'plan.notification.description.appuser': '请设置巡检人员',
  'plan.notification.description.copy.success': '计划复制成功',
  'plan.notification.description.copyfirst': '请先复制计划',
  'plan.notification.description.selfirst': '请先选择要复制的计划',
  'plan.notification.description.selone': '请选择一条记录进行操作',
  'plan.notification.description.patrolone': '巡检次数为一次，间隔时间必须设置为零',
  'plan.Modal.confirm.paste': '确定要将复制的计划粘贴到当前区域下吗？',
  'plan.Modal.confirm.restday': '请先选择特殊休息日',
  'plan.shift.day.wrong': '日计划，最多只能创建31天，请重新输入巡检周期！',
  'plan.shift.week.wrong': '周计划，最多只能创建4周，请重新输入巡检周期！',
  'plan.shift.month.wrong': '月计划，最多只能创建3个月，请重新输入巡检周期！',
  'plan.rest.wrong': '不能全设置为周休息日，请重新设置',
};
