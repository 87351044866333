export default {
  'common.table.batchdel': 'Suppression par lots',
  'common.table.del': 'Supprimer',
  'common.table.moveup': 'Déplacer vers le haut',
  'common.table.movedown': 'Descendre',
  'common.table.cancel': 'Annuler',
  'common.table.selected': 'Choisie',
  'common.table.item': 'Articles',
  'common.btn.success': 'Opération réussie',
  'common.btn.download.success': 'Téléchargement des données réussi',
  'common.btn.download.failed': 'Le téléchargement des données a échoué',
  'common.btn.confirm': 'Confirmer',
  'common.btn.cancel': 'Annuler',
  'common.btn.close': 'Proche',
  'common.btn.template': 'Télécharger le modèle',
  'common.btn.import.label': 'Nom de fichier',
  'common.btn.export.excel': 'Exporter Excel',
  'common.btn.export.pdf': 'Exporter PDF',
  'common.btn.import.title': 'Cliquez pour télécharger',
  'common.btn.home': 'Rentrer à la maison',
  'common.message.prompt': 'rapide',
  'common.placeholder.message': "Entrez s'il vous plait ",
  'common.device.nodata': "Aucune donnée dans l'appareil",
  'common.notification.description.nodata':
    'Aucun enregistrement correspondant trouvé, veuillez modifier la condition demandée.',
  'common.notification.description.batchsuccess':
    "Lot lu avec succès, le nombre total d'enregistrements est: ",
  'common.notification.description.records': " Le nombre d'enregistrements réellement entrés est: ",
  'common.notification.description.required': 'Cet article est obligatoire',
  'common.notification.query.range':
    'La plage de temps de la requête ne peut pas dépasser 60 jours. Veuillez resélectionner les critères de requête',
};
