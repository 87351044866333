export default {
  'device.title': 'Настройки параметров',
  'device.system': 'Системные настройки',
  'device.device': 'Настройки устройства',
  'device.modal.title': 'Советы',
  'device.modal.ok': 'ОК',
  'device.modal.edit': 'Изменить',
  'device.modal.save': 'Сохранить',
  'device.modal.del': 'Удалить',
  'device.modal.cancel': 'Отмена',
  'device.modal.operate': 'Операция',
  'device.modal.setsuccess': 'Установлено успешно',
  'device.modal.seterr': 'Не удалось установить',
  'device.modal.delconfirm': 'Вы уверены, что хотите удалить выбранные данные? ',
  'device.modal.delsuccess': 'Удаление данных выполнено успешно! ',
  'device.modal.delerr': 'Ошибка удаления данных! ',
  'device.system.basic': 'Основные параметры',
  'device.system.basic.event': 'Функция события',
  'device.system.basic.cycle': 'По расписанию',
  'device.system.basic.cost': 'Время патрулирования',
  'device.system.basic.cost.des':
    'Показывать ли в отчете о патрулировании плановое время каждого раунда патрулирования',
  'device.system.basic.stay': 'время пребывания',
  'device.system.basic.stay.des': 'Показывать ли в докладе время простоя',
  'device.system.basic.order': 'Проверка заказа',
  'device.system.basic.date': 'Формат даты',
  'device.system.basic.enable': 'включить',
  'device.system.basic.disenable': 'отключить',
  'device.device.btn.parameter': 'Настройки параметров устройства',
  'device.device.btn.parameter.remote': 'Удаленная настройка параметров',
  'device.device.btn.delalldevicedata': 'Вы уверены, что хотите удалить все данные с устройства? ',
  'device.device.btn.delalldeviceclock':
    'Вы уверены, что хотите отключить будильник с устройства? ',
  'device.device.btn.deldevicedata': 'Удалить данные устройства',
  'device.device.btn.conn': 'Настройка параметров связи',
  'device.device.btn.delfinger': 'Удалить отпечаток пальца устройства',
  'device.device.btn.init': 'Инициализация устройства',
  'device.device.btn.initconfirm':
    'Вы уверены, что хотите восстановить заводское состояние устройства по умолчанию? ',
  'device.device.MsgNoReader':
    'Пожалуйста, сначала вставьте инспекционное устройство, а затем свяжитесь',
  'device.device.MsgNoFount': 'Проверочное устройство не найдено, подтвердите, подключено ли оно',
  'device.device.MsgWriteFailed': 'Не удалось записать данные',
  'device.device.MsgOpenFailed': 'Не удалось открыть устройство',
  'device.device.MsgReadFailed': 'Не удалось прочитать данные устройства.',
  'device.device.MsgConnFailed': 'Ошибка подключения устройства.',
  'device.device.MsgTypeFailed': 'Ошибка типа устройства',
  'device.device.noparam': 'Текущее устройство доступа не требует настройки параметров',
  'device.device.init': 'Вы уверены, что хотите сбросить настройки устройства до заводских? ',
  'device.system.init': 'Инициализация системы',

  'device.btn.download.all': 'загрузить все данные',
  'device.btn.download.select': 'Загрузить выбранные данные',

  'device.guardname': 'Отображаемое имя сотрудника',
  'device.guardname.place': 'Пожалуйста, введите имя человека',
  'device.guardname.des': 'Имя, используемое для отображения на ЖК-экране',

  'device.clock': 'Настройка будильника',
  'device.clock.sn': 'серийный номер',
  'device.clock.name': 'Время будильника',
  'device.clock.add': 'Добавить будильник',
  'device.clock.del': 'Удалить будильник',
  'device.clock.down': 'Скачать будильник',
  'device.clock.delDown': 'Удалить загрузку',
  'device.clock.exittime': 'Введенное время будильника уже существует, пожалуйста, введите еще раз',
  'device.clock.nulltime': 'Пожалуйста, введите время будильника',
  'device.clock.max': 'Будильники могут загружать не более 200 групп, выберите еще раз',

  'device.flashlight.closemode': 'Режим закрытия фонарика',
  'device.flashlight.closemode.dafault': 'по умолчанию',
  'device.flashlight.closemode.20': 'Автоматическое закрытие через 20 секунд освещения',
  'device.flashlight.closemode.auto': 'Автоматическое закрытие',
  'device.flashlight.closemode.manual': 'Ручное закрытие',
  'device.flashlight.closemode.closetime': 'Время закрытия фонарика',

  'device.alarm.title': 'Функция кнопки будильника',
  'device.alarm.choice1':
    'Нажмите и удерживайте эту клавишу в течение 1 секунды, чтобы отправить тревожное сообщение',
  'device.alarm.choice2':
    'Нажмите и удерживайте эту клавишу в течение 2 секунд, чтобы отправить тревожное сообщение',
  'device.alarm.choice3':
    'Нажмите и удерживайте эту клавишу в течение 3 секунд, чтобы отправить тревожное сообщение',
  'device.alarm.choice4':
    'Нажмите и удерживайте эту клавишу в течение 4 секунд, чтобы отправить тревожное сообщение',
  'device.alarm.choice5':
    'Нажмите и удерживайте эту клавишу в течение 5 секунд, чтобы отправить тревожное сообщение',
  'device.alarm.choice6':
    'Нажмите и удерживайте эту клавишу в течение 6 секунд, чтобы отправить тревожное сообщение',
  'device.alarm.choice7':
    'Нажмите и удерживайте эту клавишу в течение 7 секунд, чтобы отправить тревожное сообщение',
  'device.alarm.choice8':
    'Нажмите и удерживайте эту клавишу в течение 8 секунд, чтобы отправить информацию о тревоге',
  'device.alarm.choice9':
    'Нажмите и удерживайте эту клавишу в течение 9 секунд, чтобы отправить информацию о тревоге',
  'device.alarm.choice10':
    'Нажмите и удерживайте эту клавишу в течение 10 секунд, чтобы отправить тревожное сообщение',
  'device.alarm.choice11':
    'Нажмите и удерживайте эту клавишу в течение  {count} секунд, чтобы отправить тревожное сообщение',
  'device.shuttype': 'режим выключения',
  'device.shuttype.auto': 'Автоматическое отключение',
  'device.shuttype.manual': 'Выключение вручную',
  'device.shuttype.time': 'Время выключения',
  'device.shuttype.timeandunit': 'Время выключения (минуты)',
  'device.shuttype.timeandunits': 'Время выключения (секунды)',
  'device.shuttype.autotime': 'Время автоматического выключения',
  'device.shuttype.unit': 'единица',
  'device.shuttype.unit.minute': 'минута',
  'device.shuttype.unit.seconds': 'секунды',

  'устройство.экран.разрешение': 'разрешение',
  'device.screen.recordtime': 'Время записи (секунды)',
  'device.screen.number': 'Номер SMS-центра',

  'device.drop.meter1': 'Свободное падение с высоты одного метра',
  'device.drop.meter2': 'Свободное падение с высоты двух метров',
  'device.drop.meter3': 'Три метра в свободном падении',

  'device.step.state': 'Состояние шагомера',
  'device.impact.state': 'Сбросить состояние тревоги',
  'device.impact.incline': 'Статус сигнала тревоги при наклоне',
  'device.impact.incline.param1': 'Параметры сигнала тревоги наклона 1',
  'device.impact.incline.param2': 'Параметры сигнала тревоги наклона 2',
  'device.impact.incline.param3': 'Параметры сигнала тревоги наклона 3',
  'device.impact.incline.angle': 'Угол сигнала тревоги наклона',
  'device.impact.condition': 'Состояние аварийного сигнала',
  'device.impact.static.param1': 'Параметр статической тревоги 1',
  'device.impact.static.param2': 'Параметр статической тревоги 2',
  'device.impact.static.param3': 'Параметр статической тревоги 3',
  'device.impact.static.sensitivity': 'Чувствительность',
  'device.static.condition': 'Статическое состояние тревоги',

  'device.clock.func': 'Функция будильника',
  'device.clock.h2.ipmode': 'режим связи',
  'device.clock.h2.dynamicip': 'Динамический IP',
  'device.clock.h2.staticip': 'Статический IP',

  'device.clock.h2.ipaddress': 'IP-адрес',
  'device.clock.h2.subnet': 'Маска подсети',
  'device.clock.h2.gateway': 'шлюз',
  'device.clock.h2.wifiname': 'Имя WiFi',
  'device.clock.h2.wifipwd': 'Пароль WiFi',
  'device.clock.h2.wififunc': 'Функция WiFi',
  'device.clock.nullwifiname': 'Пожалуйста, введите имя WIFI',
  'device.clock.nullwifipwd': 'Пожалуйста, введите пароль WIFI',
  'device.clock.h2.interval': 'Интервал напоминания о тревоге (в секундах)',
  'device.clock.h2.times': 'Количество напоминаний о будильнике',
  'device.clock.h2.intervaltimes': 'Интервал загрузки (секунды)',

  'device.call.type': 'Метод напоминания о вызове',
  'device.call.type.mute': 'без звука',
  'device.call.type.vibration': 'Вибрация',
  'device.call.type.ring': 'Мелодия',
  'device.call.type.both': 'Вибрация + Рингтон',

  'device.voice.state': 'Состояние голоса',
  'device.voice.volume': 'Громкость громкости',
  'device.voice.volume.high': 'высокий',
  'device.voice.volume.middle': 'Средний',
  'device.voice.volume.low': 'Низкий',

  'device.finger.state': 'Функция отпечатка пальца',
  'device.finger.verifyone': 'один раз при проверке',
  'device.finger.verifycard': 'на проверке при считывании контрольной точки',
  'device.finger.stitching': 'Время сшивания отпечатков пальцев',
  'device.finger.confirmdel': 'Подтвердить удаление всей информации об отпечатках пальцев?',
  'device.devicedata.deldatafirst':
    'Не удалось удалить отпечаток пальца. Прежде чем удалить отпечаток пальца, удалите данные с устройства.',
  'device.devicedata.confirmdel': 'Подтвердить удаление данных на устройстве?',
  'device.temperature.display': 'Температурный режим',
  'устройство.температура.цельсий': 'Цельсий',
  'устройство.температура.по Фаренгейту': 'по Фаренгейту',

  'device.gps.working': 'Режим работы',
  'device.gps.patrol': 'Режим патрулирования',
  'device.gps.coll': 'Режим сбора контрольных точек',
  'device.gps.interval': 'Время(а)',
  'device.gps.collgps': 'Время сбора GPS-позиции',

  'device.conn.title': 'Параметры связи. установка программы',
  'device.conn.type': 'связь',
  'device.conn.ip': 'соединение по IP - адресу',
  'device.conn.domain': 'связь',
  'device.conn.domain.name': 'область',
  'device.conn.port': 'портовый город',
  'device.conn.isp': 'Интернет-провайдер',
  'device.conn.mobile': 'China Mobile',
  'device.conn.unicom': 'China Unicom',
  'device.conn.otherisp': 'Другой провайдер',
  'device.conn.user': 'Пользователь',
  'device.conn.pwd': 'Пароль',
  'device.send.mode': 'Режим передачи',
  'device.send.auto': 'Отправлять данные автоматически',
  'device.send.manu': 'Отправить данные вручную',
  'device.send.func': 'Кнопка отправки',
  'device.send.func1': 'Нажмите кнопку 1 секунда для отправки данных',
  'device.send.func2': 'Нажмите кнопку 2 секунды, чтобы отправить данные',
  'device.send.func3': 'Нажмите кнопку 3 секунды, чтобы отправить данные',
  'device.send.func4': 'Нажмите кнопку 4 секунды, чтобы отправить данные',
  'device.send.func5': 'Нажмите кнопку 5 секунд, чтобы отправить данные',
  'device.send.func6': 'Нажмите кнопку 6 секунд, чтобы отправить данные',
  'device.send.func7': 'Нажмите кнопку 7 секунд, чтобы отправить данные',
  'device.send.func8': 'Нажмите кнопку 8 секунд, чтобы отправить данные',
  'device.send.func9': 'Нажмите кнопку 9 секунд, чтобы отправить данные',
  'device.send.func10': 'Нажмите кнопку 10 секунд, чтобы отправить данные',
  'device.send.func11': 'Нажмите кнопку {count} секунд, чтобы отправить данные',

  'device.read.data.loading': 'Идет загрузка...',
  'device.read.data.blank': 'Нет данных на устройстве.',
  'device.read.data.success': 'Чтение успешно, общая запись: ',
  'device.timing.success': 'Время выполнено успешно, код устройства: ',
  'device.timing.failed': 'Ошибка синхронизации',
  'device.read.failed': 'Ошибка чтения',
  'device.serverTime.error': 'Не удалось получить время сервера',
  'device.timing.devicetype': 'Тип устройства: ',

  'device.status.disconnect': 'Устройство не найдено',
  'device.status.disconnect.desc': 'Подключите ваше устройство через USB',

  'device.socket.stop': 'Драйвер связи не запущен, пожалуйста, запустите',
  'device.socket.stop.or': 'или ',
  'device.socket.download': 'скачать',
  'устройство.сокет.загрузка':
    'Драйвер сейчас загружается браузером, вы можете установить его, когда он будет завершен',

  'device.socket.exist': 'Драйвер запущен',
  'device.socket.exist.desc': 'Драйвер уже загружен и работает, осталось загрузить снова',

  'device.socket.busy': 'Драйвер занят, повторите попытку позже...',
  'device.socket.timeout': 'Тайм-аут драйвера...',

  'device.register.list': 'Типы зарегистрированных устройств',
  'device.register.list.delete':
    'Вы уверены, что хотите удалить этот тип зарегистрированного устройства?',
  'device.unregister.title': 'Тип незарегистрированного устройства',
  'device.unregister.content':
    'Незарегистрированное устройство типа {type}, зарегистрироваться сейчас?',

  'device.register.success': 'Тип устройства успешно зарегистрирован',

  'device.btn.add': 'Добавить устройство',
  'device.btn.active': 'Активировать устройство',
  'device.btn.replace': 'Заменить устройство',
  'device.btn.remark': 'Редактировать примечание',
  'device.btn.param': 'Настройка параметров',
  'device.btn.home': 'Вернуться домой',

  'device.table.device.code': 'Код устройства',
  'device.table.device.code.des': 'Введите код устройства',
  'device.table.device.type': 'Тип устройства',
  'device.table.device.time': 'Время регистрации',
  'device.table.device.account': 'Учетная запись',
  'device.table.device.name': 'Имя',
  'device.table.device.extra': 'Примечание',
  'device.table.device.range': 'Время начала и окончания',

  'device.table.card.purchase': 'Количество покупки',
  'device.table.card.Remain': 'Количество оставшихся',
  'device.table.card.time': 'Использовать время',
  'device.table.card.purchasetime': 'Время покупки',
  'device.table.card.year': 'Год',
  'device.table.card.month': ' Месяц',
  'device.table.card.date': 'День',

  'device.modal.remark.title': 'Примечание',
  'device.modal.remark.placeholder': 'Пожалуйста, введите примечание',
  'device.modal.remark.tip':
    'Если Введите название компании, оператор под может Просмотреть информацию об устройстве',

  'device.notification.description.add': 'Устройство успешно добавлено',
  'device.notification.description.noneed': 'Выбранное устройство не нужно активировать',
  'device.notification.description.noend':
    'Устройство можно активировать только по истечении почти одного месяца и близко к сроку действия.',
  'device.notification.description.type.wrong':
    'Выбранное устройство не соответствует типу устройства, соответствующему текущему коду активации, и операция активации устройства не может быть завершена.',
  'device.notification.description.success': 'Устройство успешно активировано',
  'device.notification.description.failed': 'Ошибка активного устройства',
  'device.notification.description.type':
    'Тип выбранного устройства отличается от текущего устройства. Поэтому заменить устройство нельзя. Выберите устройство того же типа',
  'device.notification.description.code':
    'Текущее устройство, которое нужно добавить, совпадает с номером выбранного устройства. Поэтому устройство нельзя заменить. Вам необходимо заменить устройство другим номером устройства',
  'device.notification.description.confirm':
    'Вы уверены, что заменили исходное устройство? После замены исходное устройство может только считывать данные до замены, действительные данные нового устройства начнутся после замены.',
  'device.notification.description.unsupport':
    'Эта функция не поддерживается текущим устройством доступа.',
  'device.notification.description.nosite':
    'В плане нет контрольной точки, сначала установите контрольную точку.',
  'device.notification.description.noguard':
    'Нет охранника, который можно загрузить. Сначала установите охранник.',
  'device.notification.description.noplan':
    'Нет плана, который можно загрузить. Сначала установите защиту.',
  'device.notification.description.clearbook':
    'Запись не выбрана для загрузки. Если запись не выбрана для загрузки, информация адресной книги в устройстве будет очищена. Продолжить эту операцию?',
  'device.notification.description.unselect':
    'Пожалуйста, сначала проверьте устройство, которым вы хотите управлять',
};
