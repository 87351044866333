export default {
  'map.alarmcontentinfo.alarmdetail': '報警詳情:',
  'map.alarmcontentinfo.unkonwdetail': '未知詳情',
  'map.alarmcontentinfo.iknow': '處理',

  'map.devicecontentinfo.unkonwperson': '未知人員',
  'map.devicecontentinfo.track.timerange': '軌迹數據回放的時間範圍',
  'map.devicecontentinfo.track.speed': '回放速度(km/h)',
  'map.devicecontentinfo.track.begin': '開始回放',

  'map.floatingtoolbar.track.replay': '重新回放',
  'map.floatingtoolbar.track.pause': '暫停',
  'map.floatingtoolbar.track.resume': '繼續',
  'map.floatingtoolbar.track.clear': '清除軌迹',
  'map.floatingtoolbar.track.resetlimit': '重置條件',

  'map.device.deviceno': '設備編號：',
  'map.search.nocondition.msg': '請輸入檢索條件！',
  'map.track.notime.msg': '請選擇軌迹回放時間範圍！',
  'map.track.empty': '無有效軌迹數據！',
  'map.site.sitename': '地點名稱',
  'map.site.address': '地址',
  'map.module.title': '實時監控',
  'map.sitecontentinfon.unknowdept': '未知區域',
  'map.sitecontentinfon.unknowperson': '未知人員',
  'map.sitecontentinfon.actionhistory': '查看曆史',
  'map.topcomponent.select.plan': '請選擇計劃',
  'map.topcomponent.device.onlinetime': '設備在線時長(單位：分鍾)',
  'map.topcomponent.screen.full': '切換全屏',

  'map.realdata.table.deptname': '區域名稱',
  'map.realdata.table.sitename': '地點名稱',
  'map.realdata.table.guardname': '人員名稱',
  'map.realdata.table.guardcode': '設備號碼',
  'map.realdata.table.happentime': '巡檢時間',

  'map.realdata.changepage.old': '切換到舊版地圖頁',
  'map.realdata.changepage.new': '切換到新版地圖頁',
  'map.realdata': '實時數據',

  'map.google.normal': '普通',
  'map.google.satellite': '衛星',

  'map.videomodal.title': '視頻',
  'map.audiomodal.title': '音頻',
  'map.button.close': '關閉',

  'map.playback': '軌跡回放',
  'map.playback.over': '軌跡回放結束，本次巡檢裏程：',
  'map.playback.mile': '米',
  'map.playback.timespan': '軌跡回放時間段不能超過2天，請重新選擇日期範圍',

  'map.load.timeout': '地圖加載異常，頁面需要重新加載',

  'map.cluster.more': '更多坐標請放大地圖查看',
  'map.site.add.confirm': '確定要將當前選擇的計劃下的點全部添加到地圖中嗎？',
  'map.site.del.confirm': '確定要將當前選擇的計劃下的點全部從地圖中刪除嗎？',
  'map.site.del.info': '請選擇壹個計劃進行操作',
};
