export default {
  'request.10000': 'Несуществующая компания',
  'request.10001': 'Ошибка имени пользователя и пароля',
  'request.10002': 'Пробный период программы истек, обратитесь к администратору',
  'request.10003': 'Введен неверный код подтверждения',
  'request.10004': 'Название компании уже существует, пожалуйста, введите еще раз',
  'request.10005': 'Номер телефона зарегистрирован, пожалуйста, не повторяйте операцию',
  'request.10006': 'Исходный пароль такой же, как и новый пароль, менять пароль не нужно',
  'request.10007': 'Текущий пользователь не существует',
  'request.10008': 'Исходный пароль был введен неверно',
  'request.10009':
    'Текущий пользователь вошел в систему и не может войти снова, повторите попытку позже',
  'request.10010':
    'Текущее количество зарегистрированных пользователей превысило максимальное количество пользователей, разрешенное системой',
  'request.10011': 'Почтовый ящик зарегистрирован, пожалуйста, не повторяйте операцию',
  'request.10012': 'Номер устройства зарегистрирован, пожалуйста, проверьте',
  'request.10013': 'Операция выполняется слишком часто, повторите попытку через 1 минуту',
  'request.10014':
    'Ошибка запуска системы. Нажмите правую кнопку для получения кода активации системы',
  'request.10015':
    'никаких прав для текущего пользователя не установлено. перед входом в систему, пожалуйста, свяжитесь с администратором, чтобы установить права доступа',
  'request.10016': 'Пожалуйста, оставьте донора.',
};
