export default {
  'login.title': 'Добро пожаловать',
  'login.subtitle': 'Инспекционная система',
  'login.type.login': 'Логин',
  'login.type.trial': 'Демо',
  'login.companyCode': 'Код предприятия',
  'login.companyCode.required': 'Пожалуйста, введите код компании',
  'login.userName': 'имя пользователя',
  'login.userName.required': 'Пожалуйста, введите имя пользователя',
  'логин.пароль': 'пароль',
  'login.password': 'пароль',
  'login.password.required': 'Пожалуйста, введите ваш пароль',
  'login.remember': 'Запомнить пароль',
  'login.guest': 'Гостевой режим',
  'login.captcha': 'код проверки',
  'login.captcha.captchaRequired': 'код проверки',
  'login.submit': 'Войти',
  'login.submitapplicaiton': 'Отправить',
  'login.captcha.required': 'Пожалуйста, выполните человеко-машинную проверку',
  'login.trial.companyName': 'Название компании',
  'login.trial.companyName.required': 'Пожалуйста, введите название компании',
  'login.trial.equipment.read': 'устройство чтения',
  'login.trial.equipment': 'Список устройств',
  'login.trial.equipment.required': 'Список устройств',
  'login.trial.contacts': 'имя контакта',
  'login.trial.contacts.required': 'Пожалуйста, введите имя контакта',
  'login.trial.contacts.phone': 'контактный телефон',
  'login.trial.contacts.phone.required': 'Пожалуйста, введите контактный телефон',
  'login.trial.captcha': 'SMS код подтверждения',
  'login.trial.captcha.required': 'Пожалуйста, введите код подтверждения',
  'login.trial.captcha.read': 'проверочный код',
  'login.trial.city': 'Город местоположения',
  'login.trial.city.required': 'Пожалуйста, выберите свой город',
  'login.trial.address': 'Адрес компании',
  'login.trial.address.en': 'Адрес компании',
  'login.trial.address.required': 'Пожалуйста, введите адрес компании',
  'login.trial.countryCode': 'код страны',
  'login.trial.timeZone': 'часовой пояс',
  'login.trial.success': 'Приложение выполнено успешно',
  'login.trial.contacts.email': 'Электронная почта',
  'login.trial.contacts.email.required': 'Пожалуйста, введите свой адрес электронной почты',
  'login.trial.alarm.mobile':
    'Код подтверждения был отправлен на ваш мобильный телефон, обратите внимание на проверку',
  'login.trial.alarm.email':
    'Код подтверждения был отправлен на ваш адрес электронной почты, внимательно проверьте его',
  'login.trail.alarm.email.not.receive': 'Невозможно получить',
  'login.trial.alarm.email.spam':
    'Если вы не получили письмо в течение длительного времени, пожалуйста, проверьте спам.',
  'login.trial.alarm.reg.mobile':
    'Информация об учетной записи отправлена ​​на ваш мобильный телефон, внимательно проверьте ее',
  'login.trial.alarm.reg.email':
    'Информация об учетной записи отправлена ​​на ваш адрес электронной почты, обратите внимание на проверку',
  'login.trial.captcha.mobile.format':
    'Неверный формат номера мобильного телефона, введите еще раз',
  'login.trial.contacts.email.format':
    'Неверный формат номера электронной почты, пожалуйста, введите еще раз',
  'login.trial.contacts.mobile.legal': 'Недопустимый формат мобильного телефона',
  'login.trial.contacts.email.legal': 'Недопустимый формат электронной почты',

  'login.app.title': 'Облачная проверка + приложение',
  'login.app.subTitle': '"Управление проверками, больше опыта + "',
  'login.app.desc1': 'Отсканируйте код, чтобы загрузить и открыть приложение',
  'login.app.desc2': 'Введите код предприятия в позицию кода службы',
  'login.app.desc3': 'Используйте имя пользователvя и пароль платформы для входа',

  'login.slogan': 'система охранного патрулирования',
  'login.vaptcha.text': 'Выполняется инициализация подключаемого модуля проверки человек-машина...',

  'login.nocaptcha.loading': 'Идет загрузка...',
  'login.nocaptcha.guide': 'Удерживая ползунок, перетащите его в крайнее правое положение',
  'login.nocaptcha.pass': 'Аутентификация пройдена',
  'login.nocaptcha.error':
    'К сожалению, что-то пошло не так, нажмите <a href="javascript:__nc.reset()">обновить</a> и повторите попытку',
  'login.nocaptcha.offline':
    'Сеть не в порядке, <a href="javascript:__nc.reset()">нажмите, чтобы обновить</a>',

  'login.expired.exit.desc': 'Срок действия входа истек, пожалуйста, войдите снова...',

  'login.companyCode.illegal': 'Незаконный код компании',
};
