export default {
  'request.10000': 'Entreprise invalide',
  'request.10001': "Nom d'utilisateur ou mot de passe invalide",
  'request.10002': "La licence d'essai a expiré",
  'request.10003': 'Saisie incorrecte du code de vérification',
  'request.10004': "Le nom de l'entreprise existe déjà, veuillez le saisir à nouveau",
  'request.10005':
    "Le numéro de téléphone mobile a été enregistré, veuillez ne pas répéter l'opération",
  'request.10006': 'Même mot de passe',
  'request.10007': "Ne pas quitter l'utilisateur",
  'request.10008': "Mauvais mot de passe d'origine",
  'request.10009':
    'Vous êtes connecté. Vous ne pouvez pas vous reconnecter. Veuillez réessayer plus tard',
  'request.10010':
    "Le nombre d'utilisateurs actuellement connectés a dépassé le nombre maximum d'utilisateurs autorisés par le système",
  'request.10011': "La boîte aux lettres a été enregistrée, veuillez ne pas répéter l'opération",
  'request.10012': "Le numéro d'équipement a été enregistré, veuillez vérifier",
  'request.10013': 'Veuillez réessayer dans 1 minute.',
  'request.10014':
    "L'activation du système a échoué. Veuillez faire un clic droit sur le Code de vérification pour obtenir le Code d'activation du système",
  'request.10015':
    "Aucune permission n'a été attribuée à l'utilisateur actuel. Avant de vous connecter au système, contactez votre administrateur pour définir les permissions",
  'request.10016': 'Veuillez insérer le dongle.',
};
