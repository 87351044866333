import menu from './fr-FR/menu';
import pwa from './fr-FR/pwa';
import exception from './fr-FR/exception';
import request from './fr-FR/request';
import login from './fr-FR/login';
import dashboard from './fr-FR/dashboard';
import header from './fr-FR/header';
import general from './fr-FR/general';
import map from './fr-FR/map';
import device from './fr-FR/device';
import system from './fr-FR/system';
import sound from './fr-FR/sound';
import communication from './fr-FR/communication';
import alarm from './fr-FR/alarm';
import dept from './fr-FR/dept';
import event from './fr-FR/event';
import guard from './fr-FR/guard';
import site from './fr-FR/site';
import role from './fr-FR/role';
import talk from './fr-FR/talk';
import user from './fr-FR/user';
import content from './fr-FR/content';
import book from './fr-FR/book';
import receipt from './fr-FR/receipt';
import plan from './fr-FR/plan';
import query from './fr-FR/query';
import common from './fr-FR/common';
import bi from './fr-FR/bi';
import company from './fr-FR/company';

export default {
  ...menu,
  ...pwa,
  ...exception,
  ...request,
  ...login,
  ...dashboard,
  ...header,
  ...general,
  ...map,

  ...device,
  ...system,
  ...sound,

  ...communication,
  ...alarm,
  ...dept,
  ...event,
  ...guard,
  ...site,
  ...common,
  ...user,
  ...role,
  ...content,
  ...book,
  ...plan,
  ...receipt,
  ...query,
  ...talk,

  ...bi,
  ...company,

  app: 'Cloud',
  producer: 'JWM Hi-Tech Development Ltd.,',
  oldaddress: "Adresse de l'ancienne version",
};
