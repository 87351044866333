export default {
  'menu.home': '首页',
  'menu.dashboard': '工作台',

  'menu.basic': '基础操作',
  'menu.basic.read': '读取数据',
  'menu.basic.history': '历史数据',
  'menu.basic.report': '巡检报告',
  'menu.basic.detail': '巡检明细',
  'menu.basic.chart': '统计图表',
  'menu.basic.alarm': '报警数据',
  'menu.basic.omit': '漏检数据',
  'menu.basic.raw': '原始数据',
  'menu.basic.calendar': '巡检日历',
  'menu.basic.log': '日志数据',
  'menu.basic.realmap': '实时监控',
  'menu.basic.attendance': '考勤数据',

  'menu.setup': '巡检设置',
  'menu.setup.dept': '区域设置',
  'menu.setup.event': '事件设置',
  'menu.setup.guard': '人员设置',
  'menu.setup.site': '地点设置',
  'menu.setup.plan': '计划设置',
  'menu.setup.book': '通讯录设置',
  'menu.setup.receipt': '回执信息',
  'menu.setup.down': '下载数据',
  'menu.setup.downparam': '下载数据',
  'menu.setup.content': '巡检内容',
  'menu.setup.voice': '语音设置',
  'menu.setup.talk': '对讲设置',
  'menu.setup.duty': '数据下载',

  'menu.data': '数据维护',
  'menu.data.device': '巡检设备列表',
  'menu.data.system': '系统参数设置',
  'menu.data.devices': '设备参数设置',
  'menu.data.params': '参数设置',
  'menu.data.role': '系统权限设置',
  'menu.data.user': '操作员管理',
};
